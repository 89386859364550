import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axiosInstance from "../common/api";
import {Button, Col, Divider, Row, Space, Table, Tag, Tooltip} from "antd";
import Title from "antd/lib/typography/Title";
import {PlusOutlined} from "@ant-design/icons";
import PermissionRequired from "../common/PermissionRequired";

const columns = [
    {
        title: 'ID',
        ellipsis: true,
        key: 'id',
        dataIndex: 'id',
        width: 70,
        render: x => <Link to={`${x}`}>{x}</Link>
    },
    {
        title: 'Nome',
        key: 'name',
        dataIndex: 'name',
        ellipsis: true,
        width: 250,
    },
    {
        title: 'Macroqualifica',
        key: 'macroMansion',
        dataIndex: 'macroMansion',
        ellipsis: true,
        width: 120,
    },
    {
        title: 'Descrizione',
        key: 'description',
        dataIndex: 'description',
        width: 500,
        ellipsis: true,
    },
];


export default function Mansions() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        axiosInstance.get("mansion",)
            .then(({data}) => setData(data))
            .finally(() => setLoading(false))
    }, []);


    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <Row>
            <Col span={24}>
                <Space wrap style={{width: '100%', justifyContent: "end"}}
                       split={<Divider type={"vertical"}/>}>
                    <PermissionRequired name={"mansion.create"}>
                        <Button type={"primary"} icon={<PlusOutlined/>}
                                style={{width: 200}}
                                onClick={() => navigate("new")}>
                            Nuova mansione
                        </Button>
                    </PermissionRequired>
                </Space>
            </Col>
        </Row>
        <Table columns={columns}
               size="small"
               style={{marginTop: 20}}
               tableLayout={"fixed"}
               dataSource={data.map(x => ({...x, key: x.id}))}
               loading={loading}
               pagination={{
                   total: data.length,
                   showTotal: count => `Totale: ${count}`,
               }}
               scroll={{x: columns.reduce((acc, x) => x.width + acc, 0)}}
        />
    </Space>;
}
