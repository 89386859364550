import { DatePicker, Input, message, Table } from "antd";
import Toolbar from "../common/Toolbar";
import dayjs from "dayjs";
import { formatDateTime } from "../common/common";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../common/api";
import { UserInfoContext } from "../FetchUserInfo";
import NewAttendanceRawRecord from "./NewAttendanceRawRecord";
import EditAttendanceRawRecord from "./EditAttendanceRawRecord";
import FilterRow from "../components/filter/FilterRow";

const columns = [
    {
        key: "employee",
        title: "Dipendente",
        dataIndex: ["employee", "fullName"],
        width: 100,
    },
    {
        title: "Data e ora",
        key: "timestamp",
        dataIndex: "timestamp",
        width: 100,
        render: formatDateTime,
    },
    {
        title: "Provenienza",
        key: "src",
        dataIndex: "source",
        width: 100,
    },
];

export default function () {
    const [result, setResult] = useState({});
    const [newAttendanceRawRecordOpen, setNewAttendanceRawRecordOpen] = useState(false);
    const [editAttendanceRawRecordOpen, setEditAttendanceRawRecordOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useContext(UserInfoContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [params, setParams] = useState({ pagination: { page: 0, pageSize: 10 } });

    const fetch = () => {
        setLoading(true);
        setSelectedRowKeys([]);
        axiosInstance
            .get("attendanceRawRecord", { params:{...params.pagination,...params.filters}})
            .then(({ data }) => setResult(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetch();
    }, [params]);

    const handleFilterChange = (filters) => {
        const adjFilters = { ...filters };
        if (adjFilters.range) {
            adjFilters.startDate = filters.range[0].format("YYYY-MM-DD");
            adjFilters.endDate = filters.range[1].format("YYYY-MM-DD");
            delete adjFilters.range;
        }
        setParams({ ...params, filters: adjFilters, pagination: { page: 0, pageSize: 10 } });
    };

    return (
        <div>
            <EditAttendanceRawRecord
                data={result?.data?.filter((x) => x.id === selectedRowKeys[0])?.[0]}
                open={editAttendanceRawRecordOpen}
                onFinish={() => {
                    setEditAttendanceRawRecordOpen(false);
                    fetch();
                }}
                onCancel={() => setEditAttendanceRawRecordOpen(false)}
            />

            <NewAttendanceRawRecord
                open={newAttendanceRawRecordOpen}
                onFinish={() => {
                    setNewAttendanceRawRecordOpen(false);
                    fetch();
                }}
                onCancel={() => setNewAttendanceRawRecordOpen(false)}
            />
            <FilterRow
                onChange={handleFilterChange}
                items={[
                    {
                        name: "query",
                        label: "Dipendente",
                        formItem: {
                            children: <Input />,
                        },
                    },
                    {
                        name: "range",
                        label: "Date",
                        readableValue: (value) =>
                            `Dal ${value[0].format("DD/MM/YYYY")} al ${value[1].format("DD/MM/YYYY")}`,
                        formItem: {
                            children: (
                                <DatePicker.RangePicker
                                    style={{ width: "100%" }}
                                    format="DD/MM/YYYY"
                                />
                            ),
                        },
                    },
                    {
                        name: "source",
                        label: "Provenienza",
                        formItem: {
                            children: <Input />,
                        },
                    },
                ]}
            />
            <Toolbar
                selectedKeys={selectedRowKeys}
                addAction={
                    user?.permissions.includes("attendanceRawRecord.create")
                        ? () => setNewAttendanceRawRecordOpen(true)
                        : null
                }
                editAction={
                    user?.permissions.includes("attendanceRawRecord.update")
                        ? (key) => {
                              if (result.data.filter((x) => x.id === key)[0].createdByThirdParty)
                                  setEditAttendanceRawRecordOpen(true);
                              else message.warning("Timbratura non modificabile");
                          }
                        : null
                }
                deleteAction={
                    user?.permissions.includes("attendanceRawRecord.delete")
                        ? (keys) => {
                              if (
                                  result.data.filter((x) => x.id === keys[0])[0].createdByThirdParty
                              ) {
                                  axiosInstance
                                      .delete(`attendanceRawRecord/${keys[0]}`)
                                      .then(fetch);
                              } else message.warning("Timbratura non eliminabile");
                          }
                        : null
                }
            />
            <Table
                columns={columns}
                loading={loading}
                rowKey="id"
                dataSource={result?.data ?? []}
                tableLayout="fixed"
                onChange={(pagination) => {
                    setParams({
                        ...params,
                        pagination: { page: pagination.current - 1, pageSize: pagination.pageSize },
                    });
                }}
                size="small"
                rowSelection={{
                    onChange: setSelectedRowKeys,
                    selectedRowKeys,
                    type: "radio",
                }}
                onRow={(record) => ({
                    onClick: () => setSelectedRowKeys([record.id]),
                })}
                pagination={{
                    total: result?.count,
                    showTotal: (total) => `Totale: ${total}`,
                    pageSize: params.pagination.pageSize,
                    current: params.pagination.page + 1,
                }}
                scroll={{ x: 700 }}
            />
        </div>
    );
}
