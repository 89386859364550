import AttachmentsByDueDate from "./AttachmentsByDueDate";
import {Button, Card, Col, Row, Space} from "antd";
import ContractsByDueDate from "./ContractsByDueDate";
import CoursesByDueDate from "./CoursesByDueDate";
import Title from "antd/lib/typography/Title";


export default function () {
    return <Space direction={"vertical"} size={30} style={{width: '100%'}}>
        <Row gutter={[10, 10]}>
            <Col span={8}>
                <Card title={'Documenti'} extra={<Button type={"link"}>Visualizza tutto</Button>}>
                    <AttachmentsByDueDate/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={'Contratti'} extra={<Button type={"link"}>Visualizza tutto</Button>}>
                    <ContractsByDueDate/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={'Corsi'} extra={<Button type={"link"}>Visualizza tutto</Button>}>
                    <CoursesByDueDate/>
                </Card>
            </Col>
        </Row>
    </Space>
}
