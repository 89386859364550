import { IdcardOutlined } from "@ant-design/icons";
import { Button, Descriptions, Form, Input, InputNumber, Modal } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import { downloadFile } from "../common/common";
import PermissionRequired from "../common/PermissionRequired";

const SetBadgeModal = ({ open, employeeId, onCancel, onFinish }) => {
    const handleOnFinish = (form) => {
        axiosInstance.put(`employee/${employeeId}/badge`, form).then(() => onFinish(form));
    };

    useEffect(()=>{
            document.body.style.overflow = 'inherit'
    },[open])
    return (
        <Modal
            open={open}
            title="Imposta numero badge"
            onCancel={onCancel}
            okButtonProps={{ htmlType: "submit", form: "setBadgeForm" }}
        >
            <Form
                id="setBadgeForm"
                onFinish={handleOnFinish}
                layout="vertical"
                style={{ marginTop: 20 }}
            >
                <Form.Item name={"badgeId"} label="Numero badge">
                    <InputNumber style={{ width: "100%" }} controls={false} precision={0} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default function ({ employee }) {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (employee) setData(employee);
    }, [employee]);

    return (
        <>
            <SetBadgeModal
                employeeId={data.id}
                open={open}
                onCancel={() => setOpen(false)}
                onFinish={(res) => {
                    setOpen(false);
                    setData({ ...data, ...res });
                }}
            />
            <Descriptions
                extra={
                    <Button
                        type="primary"
                        icon={<IdcardOutlined />}
                        onClick={() => {
                            downloadFile(`employee/${data.id}/badge`);
                        }}
                    >
                        Stampa badge
                    </Button>
                }
            >
                <Descriptions.Item label="Numero badge">
                    {data.badgeId ?? <i>Non impostato</i>}{" "}
                    <PermissionRequired name="employee.setBadge">
                        <Button size="small" type="link" onClick={() => setOpen(true)}>
                            Modifica
                        </Button>
                    </PermissionRequired>
                </Descriptions.Item>
            </Descriptions>
        </>
    );
}
