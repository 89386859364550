import { Button, Space, Table } from "antd";
import { date2locale, formatDateTime, formatTime } from "../common/common";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../common/api";
import { UserInfoContext } from "../FetchUserInfo";
import { formatTimeStr } from "antd/es/statistic/utils";
import { PlusOutlined } from "@ant-design/icons";
import NewAttendanceJustification from "../attendancejustification/NewAttendanceJustification";
import NewAttendanceRawRecord from "../attendancerawrecord/NewAttendanceRawRecord";
import FixAnomalyNewAttendanceRecord from "../attendancerawrecord/FixAnomalyNewAttendanceRecord";

const columns = (setSelectedRow, setFixOpen, setNewArrOpen, setNewJOpen) => [
    {
        key: "employee",
        title: "Dipendente",
        dataIndex: ["employee", "fullName"],
        width: 100,
    },
    {
        title: "Data",
        key: "date",
        dataIndex: "date",
        width: 100,
        render: date2locale,
    },
    {
        title: "Timbratura entrata o uscita",
        key: "start",
        dataIndex: "start",
        width: 100,
        render: (start) => start && formatTime(start),
    },
    {
        title: "Anomalia riscontrata",
        key: "reason",
        dataIndex: "reason",
        width: 150,
    },
    {
        title: "Azioni",
        key: "action",
        width: 100,
        render: (_, record) => (
            <Space>
                {record.start && (
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setNewArrOpen(true);
                            setSelectedRow(record);
                        }}
                    >
                        Timbratura
                    </Button>
                )}
                {!record.start && (
                    <>
                        <Button
                            type="link"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setFixOpen(true);
                                setSelectedRow(record);
                            }}
                        >
                            Timbrature
                        </Button>
                        <Button
                            type="link"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setNewJOpen(true);
                                setSelectedRow(record);
                            }}
                        >
                            Giustificativo
                        </Button>
                    </>
                )}
            </Space>
        ),
    },
];

export default function () {
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [newJOpen, setNewJOpen] = useState(false);
    const [newArrOpen, setNewArrOpen] = useState(false);
    const [fixOpen, setFixOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const user = useContext(UserInfoContext);
    const [selectedRow, setSelectedRow] = useState(null);

    const fetch = () => {
        setLoading(true);
        axiosInstance
            .get("attendanceAnomaly")
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetch();
    }, [searchParams]);
    return (
        <div>
            <NewAttendanceJustification
                open={newJOpen}
                data={selectedRow}
                onCancel={() => {
                    setNewJOpen(false);
                    setSelectedRow(null);
                }}
                onFinish={() => {
                    setSelectedRow(null);
                    setNewJOpen(false);
                    fetch();
                }}
            />
            <FixAnomalyNewAttendanceRecord
                open={fixOpen}
                data={selectedRow}
                onCancel={() => {
                    setFixOpen(false);
                    setSelectedRow(null);
                }}
                onFinish={() => {
                    setSelectedRow(null);
                    setFixOpen(false);
                    fetch();
                }}
            />

            <NewAttendanceRawRecord
                open={newArrOpen}
                data={selectedRow}
                onCancel={() => {
                    setNewArrOpen(false);
                    setSelectedRow(null);
                }}
                onFinish={() => {
                    setSelectedRow(null);
                    setNewArrOpen(false);
                    fetch();
                }}
            />

            <Table
                columns={columns(setSelectedRow,setFixOpen,setNewArrOpen,setNewJOpen)}
                loading={loading}
                rowKey="id"
                dataSource={data.map((x) => ({ id: x.employee.id + "_" + x.date, ...x }))}
                tableLayout="fixed"
                size="small"
                scroll={{ x: 700 }}
            />
        </div>
    );
}
