import { DatePicker, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import axiosInstance from "../common/api";
import { filterOption, formatDateTime } from "../common/common";

export default function EditAttendanceRawRecord({ data, open, onFinish, onCancel }) {
    const [form] = Form.useForm();

    useEffect(() => {
    }, [open,data]);

    useEffect(() => {
        if (!open) form.resetFields();
        else
            document.body.style.overflow = 'inherit'
    }, [open]);

    useEffect(() => {
        if(open && data){
            form.setFieldsValue({
                ...data,
                timestamp: dayjs.utc(data.timestamp).tz(dayjs.tz.guess()),
            })
        }
    },[data,open])

    const onFormFinish = (form) => {
        const formData = {
            employee: form.employee,
            timestamp: dayjs.utc(form.timestamp.tz(dayjs.tz.guess())).format(),
            src: 'web'
        };
        axiosInstance.put(`attendanceRawRecord/${data.id}`,formData)
        .then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "editAttendanceRawRecordForm",htmlType: 'submit' }}
            title="Modifica timbratura"
        >
            <Form id="editAttendanceRawRecordForm" form={form} layout="vertical" onFinish={onFormFinish}>
                <Form.Item name={"timestamp"} label="Data e ora">
                    <DatePicker format="DD/MM/YYYY HH:mm" showTime/>
                </Form.Item>
            </Form>
        </Modal>
    );
}
