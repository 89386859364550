import { Alert, Checkbox, DatePicker, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import { filterOption } from "../common/common";
import dayjs from "dayjs";

export default function ({ open, onFinish, onCancel}) {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const [justifications, setJustifications] = useState([]);

    const allDay = Form.useWatch("allDay", form);
    const range = Form.useWatch("range", form);

    useEffect(() => {
        document.body.style.overflow = 'inherit'
        if (open) {
            axiosInstance
                .get("justificationType", { params: { sign: -1 } })
                .then(({ data }) => setJustifications(data));
        }else{
            form.resetFields()
        }
    }, [open]);

    useEffect(() => {
        if (range) {
            const startDate = range[0].format("YYYY-MM-DD");
            const endDate = range[1].format("YYYY-MM-DD");
            axiosInstance
                .get("employeeCompact", { params: { startDate, endDate} })
                .then(({ data }) => setEmployees(data));
        }
    }, [range]);


    const onFormFinish = (form) => {
        const data = {
            ...form,
            startDate: form.range[0].format("YYYY-MM-DD"),
            endDate: form.range[1].format("YYYY-MM-DD"),
            allDay: form.allDay,
            excludeNonWorkingDays: form.excludeNonWorkingDays,
            minutes: form.minutes
                ? dayjs.duration(form.minutes.diff(form.minutes.startOf("day"))).asMinutes()
                : null,
        };
        axiosInstance.post("justification", data).then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "newAttendanceJustificationForm", htmlType: "submit" }}
            title="Nuovo giustificativo"
        >
            <Form
                id="newAttendanceJustificationForm"
                form={form}
                layout="vertical"
                onFinish={onFormFinish}
                style={{marginTop: 20}}
            >
                <Form.Item name={"range"} label="Date di inizio e fine" help="La data di fine è inclusiva">
                    <DatePicker.RangePicker  format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name={"employee"} label="Dipendente">
                    <Select
                        disabled={!range}
                        options={employees.map((x) => ({ value: x.id, label: x.fullName }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                <Form.Item name={"justificationTypeId"} label="Giustificativo">
                    <Select
                        options={justifications.map((x) => ({
                            label: x.id + " - " + x.name,
                            value: x.id,
                        }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                <Form.Item
                    name={"excludeNonWorkingDays"}
                    label="Escludi giorni non lavorativi"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name={"allDay"}
                    label="Tutta la giornata"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                {!allDay && (
                    <Form.Item name={"minutes"} label="Ore">
                        <DatePicker.TimePicker format="HH:mm" showNow={false} />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
}
