import "./App.css";
import {
    Badge,
    Breadcrumb,
    Button,
    Card,
    Col,
    Divider,
    Layout,
    Menu,
    Row,
    Space,
    Spin,
    theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { Footer } from "antd/lib/layout/layout";
import { Link, Navigate, Outlet, redirect, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
    AuditOutlined,
    CalendarOutlined,
    ClusterOutlined,
    ContainerOutlined,
    DashboardOutlined,
    EuroCircleOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    MenuOutlined,
    ScheduleOutlined,
    SecurityScanOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
    UserOutlined,
} from "@ant-design/icons";
import logo from "./logo.svg";
import UserAvatar from "./common/UserAvatar";
import { hasAnyRole } from "./common/common";
import { UserInfoContext } from "./FetchUserInfo";
import axiosInstance from "./common/api";

const breadcrumbNameMap = [
    { p: /\/employee$/, t: "Dipendenti" },
    { p: /\/employee\/\d+$/, t: "Profilo dipendente" },
    { p: /\/employee\/export$/, t: "Esporta documenti" },
    { p: /\/employee\/new$/, t: "Nuovo dipendente" },
    { p: /\/employee\/\d+\/edit$/, t: "Modifica dipendente" },
    { p: /\/employee\/\d+\/contract\/edit$/, t: "Modifica contratto" },
    { p: /\/employee\/\d+\/contract\/new$/, t: "Nuovo contratto" },
    { p: /\/employee\/\d+\/attachment\/new$/, t: "Nuovo documento" },
    { p: /\/employee\/\d+\/attachment\/edit$/, t: "Modifica documento" },
    { p: /\/courses$/, t: "Corsi" },
    { p: /\/courses\/plan$/, t: "Piano di formazione" },
    { p: /\/courses\/new$/, t: "Nuovo" },
    { p: /\/courses\/\d+\/edit$/, t: "Modifica corso" },
    { p: /\/deadlines$/, t: "Scadenze" },
    { p: /\/dashboard$/, t: "Dashboard" },
    { p: /\/attendances$/, t: "Presenze" },
    { p: /\/attendances\/new$/, t: "Nuovo" },
    { p: /\/attendances\/\d+\/edit$/, t: "Modifica presenza" },
    { p: /\/attendances\/\d+\/edit$/, t: "Modifica presenza" },
    { p: /\/dpi$/, t: "DPI" },
    { p: /\/dpi\/new$/, t: "Nuova consegna" },
    { p: /\/paycheck$/, t: "Cedolini" },
    { p: /\/paycheck\/new$/, t: "Caricamento cedolini" },
    { p: /\/paycheck\/resolve$/, t: "Risoluzione problemi" },
    { p: /\/expenseReports$/, t: "Note spese" },
    { p: /\/expenseReports\/\d+$/, t: "Dettaglio nota spese" },
    { p: /\/criticalissues/, t: "Criticità" },
    { p: /\/justificationsCalendar/, t: "Calendario assenze" },
    { p: /\/justifications/, t: "Assenze" },
    { p: /\/users/, t: "Utenti" },
    { p: /\/mansions/, t: "Mansioni" },
    { p: /\/attendanceRawRecords/, t: "Timbrature" },
    { p: /\/timesheetsReport/, t: "Stampa presenze" },
    { p: /\/attendanceAnomalies/, t: "Anomalie presenze" },
];
const getTitle = (url) => {
    return breadcrumbNameMap.filter((el) => el.p.test(url))[0]?.t;
};

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = useContext(UserInfoContext);
    const [collapsed, setCollapsed] = useState(false);
    const [criticalIssuesCount, setCriticalIssuesCount] = useState(0);
    const [attendanceAnomaliesCount, setAttendanceAnomaliesCount] = useState(0);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const pathSnippets = location.pathname.split("/").filter((i) => i);
    const breadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        const title = getTitle(url);
        return (
            title && {
                key: url,
                title: (
                    <Link to={url} replace>
                        {title}
                    </Link>
                ),
            }
        );
    });
    const filterItems = breadcrumbItems.filter((x) => x);
    const finalItems = filterItems.map((x, index) =>
        index === filterItems.length - 1 ? { ...x, title: getTitle(x.key) } : x,
    );

    useEffect(() => {
        if (userInfo?.permissions.includes("criticalIssue.read")) {
            axiosInstance
                .get("criticalissue")
                .then(({ data }) => setCriticalIssuesCount(data.count));
        }
        if (userInfo?.permissions.includes("attendanceAnomaly.read"))
            axiosInstance
                .get("attendanceAnomaly/count")
                .then(({ data }) => setAttendanceAnomaliesCount(data.count));
    }, []);

    const items = [
        {
            label: "Dashboard",
            icon: <DashboardOutlined />,
            permissions: ["statistics.read"],
            key: "/dashboard",
        },
        {
            label: "Scadenze",
            icon: <CalendarOutlined />,
            permissions: ["course.read", "contract.read", "employeeAttachment.read"],
            key: "/deadlines",
        },
        {
            label: (
                <div style={{ display: "inline-block" }}>
                    Criticità <Badge style={{ marginLeft: 10 }} count={criticalIssuesCount} />
                </div>
            ),
            icon: <ExclamationCircleOutlined />,
            permissions: ["criticalIssue.read"],
            key: "/criticalissues",
        },
        {
            label: "Dipendenti",
            icon: <TeamOutlined />,
            permissions: ["employee.read"],
            key: "/employee",
        },
        {
            label: "Note spese",
            icon: <ShoppingCartOutlined />,
            key: "/expenseReports",
            permissions: ["expenseReport.read"],
        },
        {
            label: "Cedolini",
            icon: <EuroCircleOutlined />,
            key: "/paycheck",
            permissions: ["paycheck.read"],
        },
        {
            label: (
                <div style={{ display: "inline-block" }}>
                    Presenze {attendanceAnomaliesCount > 0 && <Badge title="Presenze" dot />}
                </div>
            ),
            icon: <ScheduleOutlined />,
            children: [
                {
                    key: "attendanceAnomalies",
                    label: (
                        <div style={{ display: "inline-block" }}>
                            Anomalie{" "}
                            <Badge style={{ marginLeft: 10 }} count={attendanceAnomaliesCount} />
                        </div>
                    ),
                    permissions: ["attendanceAnomaly.read"],
                },
                {
                    label: "Timbrature",
                    key: "/attendanceRawRecords",
                    permissions: ["attendanceRawRecord.read"],
                },
                {
                    label: "Assenze",
                    key: "/justifications",
                    permissions: ["justification.read"],
                },
                {
                    label: "Calendario assenze",
                    key: "/justificationsCalendar",
                    permissions: ["justification.read"],
                },
                {
                    label: "Registri presenze",
                    key: "/attendances",
                    permissions: ["attendance.read"],
                },
                {
                    label: "Stampa presenze",
                    key: "/timesheetsReport",
                    permissions: ["attendanceReport.read"],
                },
            ],
        },
        {
            label: "Corsi di formazione",
            icon: <ContainerOutlined />,
            permissions: ["course.read"],
            key: "/courses",
        },
        {
            label: "Sicurezza",
            icon: <SecurityScanOutlined />,
            children: [
                {
                    label: "Consegne DPI",
                    icon: <AuditOutlined />,
                    permissions: ["dpiReport.read"],
                    key: "/dpi",
                },
                {
                    icon: <FileDoneOutlined />,
                    label: "Piano Operativo di Sicurezza",
                    permissions: ["pos.create"],
                    key: "/pos",
                },
            ],
        },
        {
            label: "Configurazione",
            icon: <SettingOutlined />,
            children: [
                {
                    label: "Utenti",
                    icon: <UserOutlined />,
                    permissions: ["user.read"],
                    key: "/users",
                },
                {
                    label: "Mansioni",
                    icon: <ClusterOutlined />,
                    permissions: ["mansion.read"],
                    key: "/mansions",
                },
            ],
        },
    ];

    const filterMenu = (menu) => {
        if (Array.isArray(menu)) return menu.map((x) => filterMenu(x)).filter((x) => !!x);

        const hasPermission = (menu.permissions ?? []).every(
            (y) => userInfo.permissions?.includes(y) ?? false,
        );
        if (!hasPermission) return null;
        if (!!menu.children) {
            const children = filterMenu(menu.children);
            if (children.length === 0) return null;
            else return { ...menu, children };
        }
        return menu;
    };

    const filteredItems = filterMenu(items);

    const allowed =
        userInfo.roles && ["hr", "admin", "assistant"].some((x) => userInfo.roles.includes(x));
    if (!allowed) return <Navigate to={"/self/attendance"} replace={true} />;
    else if (location.pathname === "/" && items.length > 0)
        return <Navigate to={filteredItems[0].key} replace={true} />;

    return (
        <Layout>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={setCollapsed}
                style={{
                    zIndex: 101,
                    overflow: "auto",
                    height: "100vh",
                    position: "sticky",
                    insetInlineStart: 0,
                    top: 0,
                    bottom: 0,
                    scrollbarWidth: "thin",
                    scrollbarGutter: "stable",
                }}
            >
                <div
                    style={{ width: "100%", textAlign: "center", marginTop: 30, marginBottom: 30 }}
                >
                    <img alt="logo" style={{ width: 40 }} src={logo} />
                </div>

                <Menu
                    mode="vertical"
                    theme="dark"
                    onSelect={({ key }) => navigate(key)}
                    items={filteredItems}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        zIndex: 100,
                        position: "sticky",
                        top: 0,
                        background: "white",
                        width: "100%",
                        boxShadow:
                            "rgba(0, 0, 0, 0.03) 0px 1px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 6px -1px, rgba(0, 0, 0, 0.02) 0px 2px 4px 0px",
                    }}
                >
                    <div style={{ height: "100%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: 16,
                                height: "100%",

                                justifyContent: "end",
                                alignItems: "center",
                            }}
                        >
                            <Breadcrumb
                                items={finalItems}
                                itemRender={(item) => <b style={{ fontSize: 20 }}>{item.title}</b>}
                            />
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                            >
                                {<UserAvatar user={{ firstName: "Nikola", lastName: "Marku" }} />}
                            </div>
                        </div>
                    </div>
                </Header>
                <Content>
                    <div style={{ padding: 16, height: "100%" }}>
                        <Card styles={{ body: { padding: '16px 36px'} }}>
                            <Outlet />
                        </Card>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default App;
