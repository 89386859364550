import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { Button, Input, Popconfirm, Select, Spin } from "antd";
import { filterOption, formatTime } from "../common/common"; // React Data Grid Component
import { themeQuartz } from "ag-grid-community";
import axiosInstance from "../common/api";
import dayjs from "dayjs";
import { LoadingOutlined, SolutionOutlined } from "@ant-design/icons";

const TimePickComponent = ({
    cellStartedEdit,
    eventKey,
    value,
    onValueChange,
    editorValueGetter,
    fieldValueSetter,
    stopEditing,
}) => {
    const regex = /^([0-9]*):?([0-5]?[0-9])?$/g;
    const ref = useRef();
    const [inputValue, setInputValue] = useState();

    useEffect(() => {
        if (cellStartedEdit) {
            ref.current.focus();
            if (eventKey?.match(/^[0-9]/)) {
                setInputValue(eventKey);
            } else {
                const curVal = editorValueGetter ? editorValueGetter(value) : value;
                if (!!curVal) setInputValue(dayjs.duration(curVal, "minutes").format("HH:mm"));
                else setInputValue();
            }
        }
    }, [cellStartedEdit]);

    return (
        <Input
            ref={ref}
            size={"small"}
            allowClear
            placeholder="00:00"
            value={inputValue}
            onPressEnter={() => {
                const groups = regex.exec(inputValue ?? "");
                if (groups) {
                    const values = groups ? groups.slice(1)?.filter((x) => !!x) : ["0", "0"];
                    const minutes = Number(values[0] ?? 0) * 60 + Number(values[1] ?? 0);
                    onValueChange(fieldValueSetter ? fieldValueSetter(value, minutes) : minutes);
                }
                stopEditing();
            }}
            variant="borderless"
            onChange={(evt) => evt.target.value.match(regex) && setInputValue(evt.target.value)}
        />
    );
};

const MyComponent = ({ eventKey, cellStartedEdit, value, onValueChange, stopEditing }) => {
    const [options, setOptions] = useState([]);
    const ref = useRef();
    const just = value?.id;
    const minutes = value?.minutes;
    const [inputValue, setInputValue] = useState()

    useEffect(() => {
        if (cellStartedEdit){
            ref.current.focus();
            if (eventKey?.match(/^[A-Za-z0-9]{1}$/)) 
                setInputValue(eventKey);
            else
                setInputValue()
        }
    }, [cellStartedEdit]);

    useEffect(() => {
        axiosInstance.get("justificationType").then(({ data }) =>
            setOptions(
                data.map((x) => ({
                    ...x,
                    label: x.id + " " + x.name,
                    value: x.id,
                })),
            ),
        );
    }, []);

    return (
        <Select
            filterOption={filterOption}
            dropdownStyle={{ width: 250 }}
            style={{ width: "100%" }}
            ref={ref}
            variant="borderless"
            defaultOpen
            optionRender={(opt) => (
                <span>
                    <b>{opt.data.id}</b> - {opt.data.name}
                </span>
            )}
            onSearch={setInputValue}
            inputValue={inputValue}
            onChange={(x) => {
                onValueChange(x && { id: x, minutes: minutes ?? 0 });
                stopEditing();
            }}
            showSearch
            size={"small"}
            allowClear
            options={options}
            value={just}
        />
    );
};
const columns = (justNumber) => [
    ...[
        {
            field: "date",
            cellRenderer: (params) => dayjs(params.value).format("ddd DD"),
            headerName: "Giorno",
            width: 150,
        },
        {
            field: "start.timestamp",
            headerName: "Entrata",
            cellRenderer: (params) => params.value && formatTime(params.value),
            width: 120,
        },
        {
            field: "end.timestamp",
            headerName: "Uscita",
            cellRenderer: (params) => params.value && formatTime(params.value),
            width: 120,
        },
        {
            field: "minutes",
            headerName: "Ore ordinarie",
            editable: true,
            cellEditor: TimePickComponent,
            suppressKeyboardEvent: (params) => params.editing && params.event.keyCode === 13,
            cellRenderer: ({ value }) =>
                value !== undefined ? dayjs.duration(value, "minutes").format("HH:mm") : "",
            width: 150,
        },
    ],
    ...[...Array(justNumber).keys()].flatMap((idx) => [
        {
            field: `justifications${idx}`,
            headerName: "Giust.",
            type: "rightAligned",
            cellRenderer: ({ value }) => value && value.id,
            width: 120,
            cellEditor: MyComponent,
            editable: ({ data }) =>
                !!data[`justifications${idx}`] ||
                (idx == 0 ? true : !!data[`justifications${idx - 1}`]),
            suppressKeyboardEvent: (params) => params.editing && params.event.keyCode === 13,
        },

        {
            field: `justifications${idx}`,
            headerName: "Ore",
            cellRenderer: ({ value }) =>
                value && dayjs.duration(value.minutes, "minutes").format("HH:mm"),
            editable: ({ data }) => !!data[`justifications${idx}`],
            cellEditor: TimePickComponent,
            suppressKeyboardEvent: (params) => params.editing && params.event.keyCode === 13,
            cellEditorParams: {
                editorValueGetter: (fieldValue) => fieldValue.minutes,
                fieldValueSetter: (oldFieldValue, val) => ({ id: oldFieldValue.id, minutes: val }),
            },
            width: 150,
            width: 80,
        },
    ]),
    {
        field: "notes",
        headerName: "Note",
        editable: true,
        cellEditor: "TextCellEditor",
        width: 250,
    },
];

export default function EmployeeTimesheet({ employeeId, period, onLoadingChange }) {
    const [data, setData] = useState([]);
    const [workingDates, setWorkingDates] = useState([]);
    const [loadingBuffer, setLoadingBuffer] = useState([]);
    const [employee, setEmployee] = useState({});

    const startDate = period.startOf("month");
    const endDate = period.endOf("month");
    const days = startDate.daysInMonth();

    useEffect(() => {
        if (!employeeId || !period) return;

        onLoadingChange(true);
        axiosInstance
            .get(`employee/${employeeId}`)
            .then(({ data }) => {
                setEmployee(data);
                return axiosInstance.get(`shift/${data.contract.shift.id}/workingDates`, {
                    params: {
                        month: startDate.month() + 1,
                        year: startDate.year(),
                    },
                });
            })
            .then(({ data }) => setWorkingDates(data));

        axiosInstance
            .get("attendance", {
                params: {
                    employeeId,
                    startDate: startDate.format("YYYY-MM-DD"),
                    endDate: endDate.format("YYYY-MM-DD"),
                    pageSize: 999999,
                    page: 0,
                },
            })
            .then(({ data }) => {
                setData(prepareData(data));
            })
            .finally(() => onLoadingChange(false));
    }, [employeeId, period]);

    const prepareData = (data) =>
        [...Array(days).keys()]
            .map((x) => startDate.add(x, "day").format("YYYY-MM-DD"))
            .map((x) => {
                const item = data.data.filter((y) => y.date === x)?.[0] ?? {};
                return prepareItem({ date: x, ...item });
            });

    const prepareItem = (item) => ({
        ...item,
        ...(item.justifications?.reduce(
            (acc, x, idx) => ({
                ...acc,
                ["justifications" + idx]: x,
            }),
            {},
        ) ?? {}),
    });

    const handleChange = (record) => {
        const justifications = [0, 1, 2]
            .filter((idx) => !!record["justifications" + idx])
            .map((idx) => record["justifications" + idx])
            .reduce((acc, curr) => [...acc, curr], []);
        const body = {
            id: record.id,
            employeeId,
            date: record.date,
            minutes: record.minutes ?? 0,
            notes: record.notes,
            justifications,
        };

        setLoadingBuffer([...loadingBuffer, true]);
        if (!body.id) {
            axiosInstance
                .post("attendance", body)
                .then((resp) =>
                    setData(
                        data.map((x) =>
                            x.date.localeCompare(resp.data.date) === 0 ? prepareItem(resp.data) : x,
                        ),
                    ),
                )
                .finally(() => setLoadingBuffer(loadingBuffer.splice(1)));
        } else {
            axiosInstance
                .put(`attendance/${body.id}`, body)
                .then((resp) =>
                    setData(
                        data.map((x) =>
                            x.date.localeCompare(resp.data.date) === 0 ? prepareItem(resp.data) : x,
                        ),
                    ),
                )
                .finally(() => setLoadingBuffer(loadingBuffer.splice(1)));
        }
    };
    const isValidAttendance = (a) => {
        const expected = a.expectedMinutes ?? 0;
        const minutes = a.minutes ?? 0;
        const timeOff = (a.justifications ?? [])
            .filter((x) => x.sign < 0)
            .reduce((acc, curr) => curr.minutes + acc, 0);
        const total = minutes + timeOff;
        return a.id && expected === total;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Popconfirm
                    title="Attenzione"
                    description="Tutte le righe esistenti verranno eliminate. Procedere?"
                    onConfirm={
                            () => axiosInstance
                                .post("attendance/generate", {
                                    employeeId,
                                    startDate: period.startOf("month").format("YYYY-MM-DD"),
                                    endDate: period.endOf("month").format("YYYY-MM-DD"),
                                })
                                .then(({ data }) => setData(prepareData({ data })))
                    }
                    okText="Procedi"
                    cancelText="Annulla"
                >
                    <Button
                        type="primary"
                        icon={<SolutionOutlined />}
                    >
                        Genera da dati 
                    </Button>
                </Popconfirm>

                <div
                    style={{
                        visibility: loadingBuffer.length > 0 ? "visible" : "hidden",
                        margin: 5,
                        flex: 1,
                        textAlign: "end",
                    }}
                >
                    <Spin indicator={<LoadingOutlined spin />} size="small" /> Salvataggio in corso
                </div>
            </div>
            <div style={{ height: 800 }}>
                <AgGridReact
                    columnDefs={columns(3)}
                    rowHeight={23}
                    rowData={data}
                    theme={themeQuartz.withParams({
                        headerRowBorder: false,
                        wrapperBorder: false,
                    })}
                    getRowStyle={(params) => {
                        if (workingDates.length == 0) return {};
                        if (
                            !workingDates.some((x) => x === params.data.date) ||
                            (!!employee?.contract.startDate &&
                                dayjs(params.data.date).isBefore(
                                    dayjs(employee?.contract.startDate),
                                )) ||
                            (!!employee?.contract.endDate &&
                                !dayjs(params.data.date).isBefore(dayjs(employee?.contract.endDate)))
                        ) {
                            return { background: "#d9d9d9" };
                        } else
                            return !isValidAttendance(params.data) ? { background: "#ffccc7" } : {};
                    }}
                    getRowId={(params) => String(params.data.date)}
                    onCellValueChanged={({ data }) => handleChange(data)}
                />
            </div>
        </div>
    );
}
