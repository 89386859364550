import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import FormPage from "../common/FormPage";
import { Button, Form, Input, Select } from "antd";
import { downloadFile, filterOption, requiredField } from "../common/common";
import TextArea from "antd/lib/input/TextArea";
import AskExportModal from "./AskExportModal";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function () {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [form] = useForm();
    const navigate = useNavigate();
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        const today = dayjs();
        const date = today.format("YYYY-MM-DD");
        axiosInstance
            .get("employeeCompact", {
                params: {
                    startDate: date,
                    endDate: date,
                },
            })
            .then(({ data }) => setEmployees(data));

        axiosInstance.get("pos/asset").then(({ data }) => {
            const mapped = data.data.map((x) => ({
                type: x.category?.name ?? "",
                identifier: x.licensePlate ?? "",
                producer: x.manufacturer ?? "",
                model: x.model ?? "",
            }));
            setAssets(mapped);
            console.log(mapped);
        });
    }, []);

    const onFinish = (data) => {
        setLoading(true);
        data.assets = data.assets?.map(JSON.parse) ?? [];
        downloadFile("/pos", "post", data)
            .then(() => setModalOpen(true))
            .finally(() => setLoading(false));
    };
    /**
     *
     String committente,
     String affidataria,
     String cse,
     String attivitaNetwork,
     String comune,
     String ubicazioneCantiere,
     String attivitaSvolta,
     Long primoSoccorso,
     Long antincendio,
     Long preposto,
     String descrizione,
     String descrizioneContesto,
     List<Long> employeeIds
     */
    const handleModalOk = () => {
        const params = (form.getFieldValue("employeeIds") ?? []).map((x) => "e=" + x).join("&");
        navigate("/employee/export?" + params, { replace: true });
        window.scrollTo(0, 0);
    };

    return (
        <>
            <FormPage
                form={form}
                onFinish={onFinish}
                colProps={{ xl: { span: 12, offset: 6 } }}
                title={"Generazione P.O.S."}
            >
                <Form.Item name={"employeeIds"} label={"Seleziona i dipendenti"}>
                    <Select
                        filterOption={filterOption}
                        mode="multiple"
                        options={employees.map((x) => ({ label: x.fullName, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item name={"assets"} label={"Seleziona mezzi e macchinari"}>
                    <Select
                        filterOption={filterOption}
                        mode="multiple"
                        options={assets.map((x) => ({
                            label: `${x.identifier} - ${x.producer} ${x.model} - ${x.type}`,
                            value: JSON.stringify(x),
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name={"model"}
                    label={"Modello POS da utilizzare"}
                    rules={[requiredField]}
                >
                    <Select
                        options={[
                            { label: "REV. 05/03/2024", value: "POS_5_3_24.docx" },
                            { label: "TIM", value: "POS_TIM.docx" },
                        ]}
                    />
                </Form.Item>
                <Form.Item name={"committente"} label={"Committente"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"affidataria"} label={"Affidataria"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"comune"} label={"Comune"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"lavoridi"} label={"Lavori di:"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"attivitaNetwork"} label={"Attività (NETWORK)"}>
                    <TextArea />
                </Form.Item>
                <Form.Item name={"ubicazioneCantiere"} label={"Ubicazione cantiere"}>
                    <TextArea />
                </Form.Item>
                <Form.Item name={"attivitaSvolta"} label={"Attività svolta:"}>
                    <Input />
                </Form.Item>
                <Form.Item name={"cse"} label={"CSE"}>
                    <TextArea />
                </Form.Item>
                <Form.Item name={"antincendio"} label={"Anti incendio"}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        options={employees.map((x) => ({
                            label:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                            value:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                        }))}
                    />
                </Form.Item>
                <Form.Item name={"primoSoccorso"} label={"Primo soccorso"}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        options={employees.map((x) => ({
                            label:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                            value:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                        }))}
                    />
                </Form.Item>
                <Form.Item name={"preposto"} label={"Preposto"}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        options={employees.map((x) => ({
                            label:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                            value:
                                x.fullName +
                                (x.businessPhoneNumber ? ` - ${x.businessPhoneNumber}` : ""),
                        }))}
                    />
                </Form.Item>
                <Form.Item name={"descrizioneContesto"} label={"8. Descrizione del contesto"}>
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name={"struttureEmergenza"}
                    label={"6.3 Strutture previste sul territorio al servizio del primo soccorso"}
                >
                    <TextArea />
                </Form.Item>
                <AskExportModal
                    isOpen={modalOpen}
                    handleOk={handleModalOk}
                    handleCancel={() => setModalOpen(false)}
                />
                <Form.Item style={{ textAlign: "end" }}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"}>
                        Genera
                    </Button>
                </Form.Item>
            </FormPage>
        </>
    );
}
