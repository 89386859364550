import React, { useEffect, useState } from "react";
import { Badge, Button, Calendar, Col, DatePicker, Drawer, Input, Row, Space } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../common/api";
import NewAttendanceJustificationBatch from "../attendancejustification/NewAttendanceJustificationBatch";
import { PlusOutlined } from "@ant-design/icons";
import PermissionRequired from "../common/PermissionRequired";
import { Divider } from "antd-mobile";

export default function ({ params }) {
    const [month, setMonth] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [data, setData] = useState({});
    const [selectedDate, setSelectedDate] = useState();

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get("justification", {
                params: {
                    startDate: month.startOf("month").format("YYYY-MM-DD"),
                    endDate: month.endOf("month").format("YYYY-MM-DD"),
                    page: 0,
                    pageSize: 999999,
                },
            })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setSelectedDate(month);
        fetchData();
    }, [month]);

    const cellRender = (value) => {
        const strValue = value.format("YYYY-MM-DD");
        return (
            <ul className="events">
                {data.data
                    ?.filter((x) => x.date === strValue)
                    .sort((a, b) => a.employee.fullName.localeCompare(b.employee.fullName))
                    .filter(
                        (x) =>
                            !searchValue ||
                            x.employee.fullName.toLowerCase().includes(searchValue.toLowerCase()),
                    )
                    .map((x) => (
                        <b key={`${x.date}_${x.employee.id}_${x.justificationType.id}`}>
                            <Badge
                                key={x.date + x.employee.id}
                                status="error"
                                text={x.employee.fullName + " - " + x.justificationType.id}
                            />
                        </b>
                    ))}
            </ul>
        );
    };

    return (
        <Space direction={"vertical"} style={{ width: "100%" }} size={0}>
            <Drawer closable open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                {selectedDate && cellRender(selectedDate)}
            </Drawer>
            <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                <div style={{flex: 1}}>
                    <Input
                        value={searchValue}
                        placeholder="Cerca dipendente"
                        style={{width: 200}}
                        onChange={({ target }) => setSearchValue(target.value)}
                    />
                </div>
                <DatePicker.MonthPicker format="MMMM-YYYY" value={month} onChange={setMonth} />
            </div>
            <Row style={{ marginTop: 10 }}>
                <Col span={24}>
                    <Calendar
                        headerRender={() => null}
                        onSelect={(date) => {
                            setDrawerOpen(true);
                            setSelectedDate(date);
                        }}
                        mode="month"
                        value={selectedDate}
                        cellRender={cellRender}
                        disabledDate={(date) => date.get("month") !== month.get("month")}
                    />
                </Col>
            </Row>
        </Space>
    );
}
