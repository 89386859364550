import EmployeesCountMyMonthChart from "./EmployeesCountMyMonthChart";
import {Card, Col, DatePicker, Row, Space} from "antd";
import React, {useEffect} from "react";
import axiosInstance from "../common/api";
import TurnoverByMonth from "./TurnoverByMonth";
import AbsenteeismChart from "./AbsenteeismChart";
import Title from "antd/lib/typography/Title";


export default function () {
    return <Space direction={"vertical"} size={30} style={{width: '100%'}}>
        <Row gutter={[10, 10]}>
            <Col sm={24} md={14}>
                <Row gutter={[10,10]}>
                    <Col span={24}>
                        <Card title={"Numero di dipendenti per mese"}>
                            <EmployeesCountMyMonthChart/>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title={"Tasso di turnover"}>
                            <TurnoverByMonth/>
                        </Card>
                    </Col>
                </Row>
            </Col>

            {/*<Col sm={24} md={10}>
                <Card title={"Tasso di assenteismo"}>
                    <AbsenteeismChart/>
                </Card>

            </Col>*/}
        </Row>
    </Space>
}
