import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Input, Select, Table, Tag } from "antd";
import axiosInstance from "../common/api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ExportOutlined, FileExcelOutlined, PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { filterOption } from "../common/common";
import Toolbar from "../common/Toolbar";
import { UserInfoContext } from "../FetchUserInfo";
import FilterRow from "../components/filter/FilterRow";

export default function Employees() {
    const [mansions, setMansions] = useState([]);
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    const user = useContext(UserInfoContext);
    const navigate = useNavigate();
    const [params, setParams] = useState({ pagination: { pageSize: 10, page: 0 } });

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get("employee", {
                params: { ...params.pagination, ...params.sort, ...params.filters },
                paramsSerializer: { indexes: null },
            })
            .then(({ data }) => {
                setResult(data);
                setLoading(false);
            });
    };

    useEffect(() => {
        axiosInstance.get("mansion").then((res) => setMansions(res.data));
    }, []);

    useEffect(() => {
        fetchData();
    }, [params]);

    const onFilterChange = (form) => {
        console.log(form)
        setParams({ ...params, filters: form, pagination: { page: 0, pageSize: 10 } });
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 100,
            sorter: true,
            sortOrder:
                params.sort?.sortField === "id" && params.sort?.sortDirection
                    ? params.sort.sortDirection === "asc"
                        ? "ascend"
                        : "descend"
                    : null,
            fixed: "left",
            render: (id, record) => (
                <Link to={"/employee/" + record.id}>{"E" + ("" + id).padStart(3, "0")}</Link>
            ),
        },
        {
            title: "Codice fiscale",
            dataIndex: "fiscalCode",
            fixed: "left",
            width: 200,
            key: "fiscalCode",
        },
        {
            title: "Nominativo",
            sorter: true,
            key: "name",
            ellipsis: true,
            sortOrder:
                params.sort?.sortField === "name" && params.sort?.sortDirection
                    ? params.sort.sortDirection === "asc"
                        ? "ascend"
                        : "descend"
                    : null,
            width: 200,
            dataIndex: "fullName",
        },
        {
            title: "Mansione",
            key: "mansions",
            dataIndex: "mansion",
            width: 200,
            render: (mansion) => <Tag color={"geekblue"}>{mansion}</Tag>,
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <FilterRow
                onChange={onFilterChange}
                items={[
                    {
                        name: "q",
                        label: "Nome o identificativo",
                        formItem: {
                            children: <Input />,
                        },
                    },
                    {
                        name: "mansions",
                        label: "Mansione",
                        readableValue: (value) => mansions.filter((x) => x.id === value)[0].name,
                        formItem: {
                            children: (
                                <Select
                                    filterOption={filterOption}
                                    showSearch
                                    options={mansions.map((x) => ({ label: x.name, value: x.id }))}
                                />
                            ),
                        },
                    },
                    {
                        name: "activeOnly",
                        label: "Solo attivi",
                        initialValue: true,
                        readableValue: (value) => (value ? "Si" : "No"),
                        formItem: {
                            props: {
                                valuePropName: "checked",
                            },
                            children: <Checkbox />,
                        },
                    },
                ]}
            />
            <div>
                <Toolbar
                    addAction={
                        user.permissions?.includes("employee.create") ? () => navigate("new") : null
                    }
                    extra={() => (
                        <>
                            {user.permissions?.includes("exportDocuments") && (
                                <Button
                                    type={"link"}
                                    icon={<ExportOutlined />}
                                    onClick={() => navigate("export")}
                                >
                                    Esporta documenti
                                </Button>
                            )}
                        </>
                    )}
                />
                <Table
                    columns={columns}
                    tableLayout={"fixed"}
                    size="small"
                    dataSource={result?.data.map((x) => ({ ...x, key: x.id }))}
                    loading={loading}
                    onChange={(pagin, filters, sorter) => {
                        const pagination = {
                            page: pagin.current - 1,
                            pageSize: pagin.pageSize,
                        };
                        const sort = sorter.columnKey ? {
                            sortDirection:
                                sorter.order && sorter.order === "ascend" ? "asc" : "desc",
                            sortField: sorter.columnKey,
                        } : {};
                        setParams({ ...params, pagination, sort });
                    }}
                    pagination={{
                        total: result?.count,
                        showTotal: (total) => `Totale: ${total}`,
                        pageSize: params.pagination?.pageSize ?? 0,
                        current: (params.pagination?.page ?? 0) + 1,
                    }}
                    scroll={{ x: 700 }}
                />
            </div>
        </div>
    );
}
