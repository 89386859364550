import { Checkbox, DatePicker, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import { filterOption } from "../common/common";
import dayjs from "dayjs";

export default function ({ open, onFinish, onCancel, data }) {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const [justificationTypes, setJustificationTypes] = useState([]);

    const allDay = Form.useWatch("allDay", form);

    useEffect(() => {
        document.body.style.overflow = "inherit";
        if (!open) form.resetFields();
    }, [open]);

    useEffect(() => {
        if (open && data) {
            form.setFieldValue("employee", data.employee.id);
            form.setFieldValue("date", dayjs(data.date));
            form.setFieldValue("justificationTypeId", data.justificationType.id);
            form.setFieldValue(
                "minutes",
                dayjs(dayjs.duration(data.minutes, "minutes").format("HH:mm"), "HH:mm"),
            );
            setEmployees([data.employee]);
            setJustificationTypes([data.justificationType]);
        } else form.resetFields();
    }, [open, data]);

    const onFormFinish = (form) => {
        const body = {
            ...form,
            minutes: form.minutes
                ? dayjs.duration(form.minutes.diff(form.minutes.startOf("day"))).asMinutes()
                : null,
        };
        axiosInstance.put(`justification/${data.id}`, body).then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "editJustificationForm", htmlType: "submit" }}
            title="Nuovo giustificativo"
        >
            <Form id="editJustificationForm" form={form} layout="vertical" onFinish={onFormFinish}>
                <Form.Item name={"date"} label="Data">
                    <DatePicker disabled format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name={"employee"} label="Dipendente">
                    <Select
                        disabled
                        options={employees.map((x) => ({ value: x.id, label: x.fullName }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                <Form.Item name={"justificationTypeId"} label="Giustificativo">
                    <Select
                        disabled
                        options={justificationTypes.map((x) => ({ value: x.id, label: x.name }))}
                    />
                </Form.Item>
                <Form.Item
                    name={"allDay"}
                    label="Tutta la giornata"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox />
                </Form.Item>
                {!allDay && (
                    <Form.Item name={"minutes"} label="Ore">
                        <DatePicker.TimePicker format="HH:mm" showNow={false} />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
}
