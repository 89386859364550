import { DatePicker, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import axiosInstance from "../common/api";
import { filterOption } from "../common/common";

export default function NewAttendanceRawRecord({ data, open, onFinish, onCancel }) {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);

    const timestamp = Form.useWatch("timestamp", form);

    useEffect(() => {
        document.body.style.overflow = 'inherit'
        if (open && !data && !!timestamp) {
            const dateStr = timestamp.format("YYYY-MM-DD");
            axiosInstance
                .get("employeeCompact", { params: { startDate: dateStr, endDate: dateStr } })
                .then(({ data }) => setEmployees(data));
        }
    }, [open, timestamp,data]);

    useEffect(() => {
        if (!open) form.resetFields();
    }, [open]);

    useEffect(() => {
        if(data){
            form.setFieldValue('date',dayjs(data.date))
            form.setFieldValue('employee',data.employee.id)
            form.setFieldValue('timestamp',dayjs(data.start).startOf('day'));
            setEmployees([data.employee])
        }
    },[data])

    const onFormFinish = (form) => {
        const data = {
            employee: form.employee,
            timestamp: dayjs.utc(form.timestamp.tz(dayjs.tz.guess())).format(),
            src: 'web'
        };
        axiosInstance.post('attendanceRawRecord',[data])
        .then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "newAttendanceRawRecordForm",htmlType: 'submit' }}
            title="Nuova timbratura"
        >
            <Form id="newAttendanceRawRecordForm" form={form} layout="vertical" onFinish={onFormFinish}>
                <Form.Item name={"timestamp"} label="Data e ora">
                    <DatePicker format="DD/MM/YYYY HH:mm" showTime/>
                </Form.Item>
                <Form.Item name={"employee"} label="Dipendente">
                    <Select
                        disabled={!timestamp || !!data}
                        options={employees.map((x) => ({ value: x.id, label: x.fullName }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
