import React, { useState } from "react";
import { DatePicker, Input } from "antd";
import { BarsOutlined, CalendarOutlined } from "@ant-design/icons";
import FilterRow from "../components/filter/FilterRow";
import { Segmented } from "antd-mobile";
import JustificationsCalendar from "./JustificationsCalendar";
import JustificationsTable from "./JustificationsTable";

export default function () {
    const [filters, setFilters] = useState({});

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <FilterRow
                style={{ flex: 1 }}
                onChange={setFilters}
                items={[
                    {
                        name: "query",
                        label: "Dipendente",
                        formItem: {
                            children: <Input />,
                        },
                    },
                    {
                        name: "range",
                        label: "Date",
                        readableValue: (value) => `Dal ${value[0].format("DD/MM/YYYY")} al ${value[1].format("DD/MM/YYYY")}`,
                        formItem: {
                            children: (
                                <DatePicker.RangePicker
                                    style={{ width: "100%" }}
                                    format="DD/MM/YYYY"
                                />
                            ),
                        },
                    },
                ]}
            />
            <JustificationsTable filters={filters} />
        </div>
    );
}
