import { Col, List, Row,  Tag } from "antd";
import {  renderDaysLeft } from "../common/common";
import { Link } from "react-router-dom";
import RemoteList from "./RemoteList";

export default function AttachmentsByDueDate() {
    return (
        <RemoteList
            params={{
                activeEmployeeOnly: true,
                latestOnly: true,
                activeOnly: true,
                sortField: "dueDate",
                sortDirection: "asc",
                page: 0,
                pageSize: 20
            }}
            path={"attachment"}
            renderItem={(item, index) => (
                <List.Item>
                    <Row style={{ width: "100%" }}>
                        <Col span={8}>
                            <Link to={"/employee/" + item.employee.id+ "?tab=2"}>
                                <u>{item.employee.fullName}</u>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <Tag color={item.tag.color}>{item.tag.name}</Tag>
                        </Col>
                        <Col span={8} style={{ textAlign: "end" }}>
                            {renderDaysLeft(item.dueDate)}
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    );
}
