import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Select, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import axiosInstance from "../common/api";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { downloadFile } from "../common/common";
import PdfViewer from "../common/PdfViewer";
import Toolbar from "../common/Toolbar";
import { UserInfoContext } from "../FetchUserInfo";
import PermissionRequired from "../common/PermissionRequired";

const ACTIVE_STATE = "1";
const EXPIRED_STATE = "2";
const NOT_RENEWED_STATE = "3";

const stateOptions = [
    {
        label: "Solo attivi",
        value: ACTIVE_STATE,
    },
    {
        label: "Solo scaduti",
        value: EXPIRED_STATE,
    },
    {
        label: "Scaduti e non rinnovati",
        value: NOT_RENEWED_STATE,
    },
];

const stateMapping = {
    [ACTIVE_STATE]: "active=true",
    [EXPIRED_STATE]: "active=false",
    [NOT_RENEWED_STATE]: "active=false&maxOnly=true",
};

export default function Courses() {
    const [courseTypes, setCourseTypes] = useState([]);
    const [result, setResult] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();

    const page = Number(searchParams.get("p") ?? "0");
    const user = useContext(UserInfoContext);
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q");
    const sortField = searchParams.get("sortField");
    const sortOrder = searchParams.get("sortOrder");
    const courseTypesFilter = searchParams.get("courseTypes")?.split(",") ?? [];

    const state = searchParams.get("state") ?? ACTIVE_STATE;

    const [searchQuery, setSearchQuery] = useState(query);

    const [openCourse, setOpenCourse] = useState(null);

    const filterParams = [
        query && "q=" + query,
        sortField && "sortField=" + sortField,
        sortOrder && "sortOrder=" + sortOrder,
        page && "page=" + page,
        pageSize && "pageSize=" + pageSize,
        state && stateMapping[state],
        ...courseTypesFilter.map((x) => "courseTypes=" + x),
    ]
        .filter((x) => x)
        .join("&");

    const fetch = () => {
        setLoading(true);
        axiosInstance.get("courseType").then((res) => setCourseTypes(res.data));
        return axiosInstance.get("course?" + filterParams).then((res) => {
            setResult(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        setSelectedRowKeys([]);
        fetch();
    }, [searchParams]);

    useEffect(() => {
        if (reload) fetch().finally(() => setReload(false));
    }, [reload]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: "left",
            width: 70,
        },
        {
            title: "Corso",
            key: "courseType",
            filters: courseTypes.map((x) => ({ text: x.title, value: x.id })),
            filterSearch: true,
            ellipsis: true,
            filteredValue: courseTypesFilter.map((x) => Number(x)),
            width: 150,
            render: (_, item) => (
                <Link onClick={() => setOpenCourse(encodeURI(`/course/${item.id}/file`))}>
                    {item.courseType.title}
                </Link>
            ),
        },
        {
            title: "Dipendente",
            sorter: true,
            fixed: "left",
            ellipsis: true,
            key: "name",
            dataIndex: ["employee", "fullName"],
            sortOrder: sortField === "name" ? (sortOrder === "asc" ? "ascend" : "descend") : null,
            width: 150,
        },
        {
            key: "isbase",
            dataIndex: "isBase",
            title: "Tipo attestato",
            ellipsis: true,
            width: 100,
            render: (v) => (v ? "Base" : "Aggiornamento"),
        },
        {
            title: "Data scadenza",
            sorter: true,
            key: "dueDate",
            sortOrder:
                sortField === "dueDate" ? (sortOrder === "asc" ? "ascend" : "descend") : null,
            ellipsis: true,
            width: 120,
            render: (_, record) =>
                dayjs(record.date, "YYYY-MM-DD")
                    .add(record.courseType.yearsDuration, "year")
                    .format("DD/MM/YYYY"),
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <PdfViewer url={openCourse} onClose={() => setOpenCourse(null)} />
            <Row>
                <Col span={12}>
                    <Space size={20} wrap>
                        <Search
                            value={searchQuery}
                            onChange={({ target }) => setSearchQuery(target.value)}
                            placeholder="Cerca"
                            allowClear
                            style={{ width: 200 }}
                            onSearch={(text) => {
                                searchParams.set("q", text);
                                searchParams.delete("p");
                                setSearchParams(searchParams, { replace: true });
                            }}
                        />
                        <Select
                            value={state}
                            options={stateOptions}
                            style={{ width: 200 }}
                            onChange={(x) => {
                                searchParams.set("state", x);
                                searchParams.delete("p");
                                setSearchParams(searchParams, { replace: true });
                            }}
                        />
                    </Space>
                </Col>
                <Col span={12} style={{ textAlign: "end" }}>
                    <Button
                        type={"text"}
                        danger
                        onClick={() => {
                            setSearchParams(undefined, { replace: true });
                            setSearchQuery("");
                        }}
                    >
                        Cancella filtri
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Toolbar
                        addAction={
                            user?.permissions.includes("course.create")
                                ? () => navigate("new")
                                : null
                        }
                        selectedKeys={selectedRowKeys}
                        editAction={
                            user?.permissions.includes("course.update")
                                ? (key) => navigate(`${key}/edit`)
                                : null
                        }
                        deleteAction={
                            user?.permissions.includes("course.delete")
                                ? (keys) => {
                                      axiosInstance.delete(`course/${keys[0]}`).finally(() => {
                                          setSelectedRowKeys([]);
                                          fetch();
                                      });
                                  }
                                : null
                        }
                        extra={(keys) => (
                            <>
                                <PermissionRequired name={"course.read"}>
                                    <Button
                                        type="text"
                                        disabled={keys.length !== 1}
                                        icon={<DownloadOutlined />}
                                        onClick={() => downloadFile(`course/${keys[0]}/file`)}
                                    >
                                        Scarica
                                    </Button>
                                </PermissionRequired>
                                <Button
                                    style={{ width: 200 }}
                                    type={"text"}
                                    icon={<FileExcelOutlined />}
                                    onClick={() => navigate("plan")}
                                >
                                    Piano di formazione
                                </Button>
                            </>
                        )}
                    />
                    <Table
                        columns={columns}
                        tableLayout={"fixed"}
                        dataSource={result?.data.map((x) => ({ ...x, key: x.id }))}
                        loading={loading}
                        onChange={(pagination, filters, sorter) => {
                            searchParams.set("p", "" + (pagination.current - 1));
                            searchParams.set("ps", "" + pagination.pageSize);
                            if (filters.courseType && filters.courseType.length > 0)
                                searchParams.set("courseTypes", filters.courseType.join(","));
                            else searchParams.delete("courseTypes");
                            if (sorter.order) {
                                searchParams.set("sortField", "" + sorter.columnKey);
                                searchParams.set(
                                    "sortOrder",
                                    sorter.order === "ascend" ? "asc" : "desc",
                                );
                            } else
                                ["sortField", "sortOrder"].forEach((x) => searchParams.delete(x));
                            setSearchParams(searchParams, { replace: true });
                        }}
                        pagination={{
                            total: result?.count,
                            pageSize: pageSize,
                            current: page + 1,
                        }}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: setSelectedRowKeys,
                            type: "radio",
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setSelectedRowKeys([record.key]);
                                }, // click row
                            };
                        }}
                        scroll={{ x: 740 }}
                        size={"small"}
                    />
                </Col>
            </Row>
        </div>
    );
}
