import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Form, Typography } from "antd";
import axiosInstance from "../common/api";
import { downloadFile } from "../common/common";

export default function () {
    return (
        <>
            <Typography.Title level={4}>Stampa registri</Typography.Title>
            <Form
                layout="vertical"
                onFinish={(form) =>
                    downloadFile(
                        `attendanceReport/employeeTimesheets?month=${form.date.format("YYYY-MM-DD")}`,
                    )
                }
            >
                <Form.Item name={"date"} label="Periodo">
                    <DatePicker.MonthPicker format="MMMM YYYY" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" icon={<FilePdfOutlined />} htmlType="submit">
                        Stampa
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
            <Typography.Title level={4}>Scarica file presenze</Typography.Title>
            <Form
                layout="vertical"
                onFinish={(form) =>
                    downloadFile(
                        `attendanceExport?month=${form.date.format("YYYY-MM-DD")}`,
                    )
                }
            >
                <Form.Item name={"date"} label="Periodo">
                    <DatePicker.MonthPicker format="MMMM YYYY" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" icon={<DownloadOutlined/>} htmlType="submit">
                        Scarica 
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
