import FormPage from "../common/FormPage";
import { Button, Form, Input, Select, Transfer } from "antd";
import { filterOption, requiredField } from "../common/common";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import SimpleTransfer from "../common/SimpleTransfer";
import { useForm } from "antd/lib/form/Form";

export default function MansionForm({ title, onFinish, initialValues }) {
    const [loading, setLoading] = useState(false);

    const [attachmentTags, setAttachmentTags] = useState([]);
    const [dpiTypes, setDpiTypes] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [macroMansions, setMacroMansions] = useState([]);
    const [form] = useForm();

    useEffect(() => {
        console.log(initialValues);
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        [
            ["tag", setAttachmentTags],
            ["dpiType", setDpiTypes],
            ["courseType", setCourseTypes],
            ["macroMansion", setMacroMansions],
        ].forEach((x) => {
            axiosInstance
                .get(x[0])
                .then(({ data }) => x[1](data.map((x) => ({ ...x, key: x.id }))));
        });
    }, []);
    return (
        <FormPage
            title={title}
            form={form}
            onFinish={(form) => {
                onFinish(form).finally(() => setLoading(false));
            }}
            colProps={{ xl: { span: 14, offset: 5 } }}
        >
            <Form.Item name={"name"} label={"Nome"} rules={[requiredField]}>
                <Input />
            </Form.Item>

            <Form.Item name={"macroMansion"} label="Macroqualifica">
                <Select
                    options={macroMansions.map((x) => ({ value: x.id, label: x.name }))}
                    filterOption={filterOption}
                    showSearch
                />
            </Form.Item>
            <Form.Item name={"description"} label={"Descrizione"}>
                <Input />
            </Form.Item>
            <Form.Item name={"attachmentTags"} label={"Documenti obbligatori"}>
                <SimpleTransfer dataSource={attachmentTags} render={(x) => x.name} />
            </Form.Item>
            <Form.Item name={"courseTypes"} label={"Corsi di formazione obbligatori"}>
                <SimpleTransfer dataSource={courseTypes} render={(x) => x.title} />
            </Form.Item>
            <Form.Item name={"dpiTypes"} label={"DPI obbligatori"}>
                <SimpleTransfer dataSource={dpiTypes} render={(x) => x.name} />
            </Form.Item>
            <Form.Item style={{ textAlign: "end" }}>
                <Button
                    loading={loading}
                    htmlType={"submit"}
                    type={"primary"}
                    style={{ width: 120 }}
                >
                    Salva
                </Button>
            </Form.Item>
        </FormPage>
    );
}
