import { FilterOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Tag } from "antd";
import { useEffect, useState } from "react";

export default function ({ items, onChange, style }) {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(
            values.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {}),
        );
    }, [values]);

    const handleOnFinish = (form) => {
        console.log(form);
        const values = Object.entries(form)
            .filter(([_, v]) => v != null)
            .map(([k, v]) => {
                const item = items.filter((x) => x.name === k)[0];
                return {
                    value: v,
                    name: k,
                    readableName: item.label,
                    readableValue: item.readableValue?.(v) ?? v,
                };
            });
        handleChange(values);
        setOpen(false);
    };

    const handleChange = (values) => {
        setValues(values);
        const transf = values.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {});
        onChange(transf);
    };

    return (
        <div style={style}>
            <div style={{ display: "flex", flexDirection: "row", columnGap: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        columnGap: 5,
                        alignItems: "center",
                    }}
                >
                    {values.length > 0 && <>Filtri:</>}
                    {values.map((x) => (
                        <Tag
                            key={x.name}
                            closable
                            onClose={() => handleChange(values.filter((z) => z.name !== x.name))}
                        >
                            {x.readableName}: {x.readableValue}
                        </Tag>
                    ))}
                </div>
                <Button
                    color="primary"
                    variant={values.length > 0 ? "filled" : "link"}
                    icon={<FilterOutlined />}
                    onClick={() => setOpen(true)}
                >
                    Filtri
                </Button>
            </div>
            <Drawer forceRender open={open} closable onClose={() => setOpen(false)} title="Filtri">
                <Form
                    onFinish={(form) => {
                        handleOnFinish(form);
                    }}
                    initialValues={items
                        .filter(x=> x.initialValue != null)
                        .reduce(
                        (acc, x) => ({ ...acc, [x.name]: [x.initialValue] }),
                        {},
                    )}
                    layout="vertical"
                    style={{ height: "100%", display: "flex", flexDirection: "column" }}
                    form={form}
                >
                    <div
                        style={{
                            flex: "1 1 0",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            scrollbarGutter: "stable",
                        }}
                    >
                        {(items ?? []).map((x) => (
                            <Form.Item
                                key={x.name}
                                name={x.name}
                                label={x.label}
                                {...x.formItem.props}
                            >
                                {x.formItem.children}
                            </Form.Item>
                        ))}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            columnGap: 16,
                        }}
                    >
                        <Button
                            onClick={() => {
                                form.resetFields();
                                handleChange([]);
                                setOpen(false);
                            }}
                        >
                            Reset
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Applica
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    );
}
