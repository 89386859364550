import {Button, DatePicker, Form, Input} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {fiscalCodeField, requiredField} from "../common/common";
import FormPage from "../common/FormPage";
import LocaleDatePicker from "../common/LocaleDatePicker";


export default function NewEmployee() {
    const navigate = useNavigate();

    const save = (form) => {
        const body = {...form, birthday: form.birthday.format("YYYY-MM-DD")}
        axiosInstance.post("employee", body)
            .then(({data}) => navigate(`/employee/${data.id}`, {replace: true}))
    }

    return <FormPage onFinish={save} title={"Nuovo dipendente"}>

        <Form.Item label={"Codice dipendente (Paghe)"} name={"payrollId"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci il codice dipendente"}/>
        </Form.Item>

        <Form.Item label={"Matricola dipendente (Paghe)"} name={"externalId"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci la matricola dipendente"}/>
        </Form.Item>

        <Form.Item label={"Nome"} name={"firstName"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci il nome"}/>
        </Form.Item>

        <Form.Item label={"Cognome"} name={"lastName"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci il cognome"}/>
        </Form.Item>

        <Form.Item label={"Codice fiscale"} name={"fiscalCode"}
                   rules={[requiredField, fiscalCodeField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci il cognome"}/>
        </Form.Item>

        <Form.Item label={"Data di nascita"} name={"birthday"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <LocaleDatePicker/>
        </Form.Item>

        <Form.Item label={"Telefono"} name={"phoneNumber"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci il cognome"}/>
        </Form.Item>
        <Form.Item
            label={"E-mail"}
            name={"email"}
            rules={[requiredField, {type: "email", message: "E-mail non valida"}]}
            hasFeedback
        >
            <Input placeholder={"Inserisci l'email"}/>
        </Form.Item>

        <Form.Item label={"Indirizzo"} name={"address"}
                   rules={[requiredField]}
                   hasFeedback
        >
            <Input placeholder={"Inserisci l'indirizzo completo"}/>
        </Form.Item>
        <Form.Item wrapperCol={{span: 24}} style={{textAlign: 'end'}}>
            <Button type={"primary"} htmlType={"submit"}>Salva</Button>
        </Form.Item>
    </FormPage>
}
