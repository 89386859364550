import React, { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../common/api";
import Toolbar from "../common/Toolbar";
import { UserInfoContext } from "../FetchUserInfo";
import dayjs from "dayjs";
import NewAttendanceJustificationBatch from "../attendancejustification/NewAttendanceJustificationBatch";
import { Table } from "antd";
import EditJustification from "./EditJustification";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const columns = [
    {
        title: "Dipendente",
        dataIndex: ["employee", "fullName"],
        width: 200,
    },
    {
        title: "Data",
        dataIndex: "date",
        width: 100,
        key: "date",
        render: (x) => dayjs(x).format("DD/MM/YYYY"),
    },
    {
        title: "Giustificativo",
        key: "justificationType",
        width: 150,
        dataIndex: ["justificationType", "name"],
    },
    {
        title: "Ore",
        key: "minutes",
        width: 100,
        dataIndex: "minutes",
        render: (x) => dayjs.duration(x, "minutes").format("HH:mm"),
    },
];

export default function ({ filters }) {
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    const user = useContext(UserInfoContext);
    const [params, setParams] = useState({ pagination: { pageSize: 10, page: 0 } });
    const [newJOpen, setNewJOpen] = useState(false);
    const [editJOpen, setEditJOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState(false);
    const initialRender = useRef(true);

    const fetchData = () => {
        setLoading(true);
        setSelectedRowKeys([]);
        axiosInstance
            .get("justification", {
                params: { ...params.pagination, ...params.filters },
                paramsSerializer: { indexes: null },
            })
            .then(({ data }) => {
                setResult({
                    ...data,
                    data: data.data.map((x) => ({
                        ...x,
                        id: `${x.employee.id}_${x.justificationType.id}_${x.date}`,
                    })),
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [params]);

    useEffect(() => {
        if (!initialRender.current) {
            const adjFilters = { ...filters };
            if (adjFilters.range) {
                adjFilters.startDate = filters.range[0].format("YYYY-MM-DD");
                adjFilters.endDate = filters.range[1].format("YYYY-MM-DD");
                delete adjFilters.range;
            }
            setParams({ ...params, filters: adjFilters, pagination: { page: 0, pageSize: 10 } });
        }
        initialRender.current = false;
    }, [filters]);


    const deleteRecords = keys => {
        axiosInstance.delete(`justification/${keys[0]}`)
        .then(fetchData)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <NewAttendanceJustificationBatch
                open={newJOpen}
                onCancel={() => setNewJOpen(false)}
                onFinish={() => {
                    setNewJOpen(false);
                    fetchData();
                }}
            />
            <EditJustification
                data={result?.data?.filter((x) => x.id === selectedRowKeys?.[0])?.[0]}
                open={editJOpen}
                onCancel={() => {
                    setEditJOpen(false);
                }}
                onFinish={() => {
                    setEditJOpen(false);
                    fetchData();
                }}
            />
            <div>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        user.permissions?.includes("justification.create")
                            ? () => setNewJOpen(true)
                            : null
                    }
                    editAction={
                        user.permissions?.includes("justification.update")
                            ? () => setEditJOpen(true)
                            : null
                    }
                    deleteAction={
                        user.permissions?.includes("justification.update")
                            ? deleteRecords 
                            : null
                    }
                />
                <Table
                    columns={columns}
                    tableLayout={"fixed"}
                    size="small"
                    rowKey={"id"}
                    dataSource={result?.data.map((x) => ({ ...x, key: x.id }))}
                    loading={loading}
                    onChange={(pagin) => {
                        const pagination = {
                            page: pagin.current - 1,
                            pageSize: pagin.pageSize,
                        };
                        setParams({ ...params, pagination });
                    }}
                    pagination={{
                        total: result?.count,
                        showTotal: (total) => `Totale: ${total}`,
                        pageSize: params.pagination?.pageSize ?? 0,
                        current: (params.pagination?.page ?? 0) + 1,
                    }}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                        type: "radio",
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                setSelectedRowKeys([record.key]);
                            }, // click row
                        };
                    }}
                    scroll={{ x: 700 }}
                />
            </div>
        </div>
    );
}
