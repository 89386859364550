import { Button, DatePicker, Form, Modal, Select, Space } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import axiosInstance from "../common/api";
import { filterOption } from "../common/common";

export default function ({ data, open, onFinish, onCancel }) {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const [shiftTimes, setShiftTimes] = useState([]);

    useEffect(() => {
        if (!open) form.resetFields();
    }, [open]);

    const getUtcDateTime = (dateObj, timeObj) => {
        const ts = dateObj.set("minute", timeObj.get("minute")).set("hour", timeObj.get("hour"));
        return dayjs.utc(ts.tz(dayjs.tz.guess())).format();
    };

    useEffect(() => {
        if (data) {
            axiosInstance
                .get(`employee/${data.employee.id}`)
                .then(({ data }) =>
                    setShiftTimes([
                        dayjs(data.contract.shift.startTime, "HH:mm"),
                        dayjs(data.contract.shift.endTime, "HH:mm"),
                    ]),
                );
            form.setFieldValue("date", dayjs(data.date));
            form.setFieldValue("employee", data.employee.id);
            form.setFieldValue("timestamp", dayjs(data.start).startOf("day"));
            setEmployees([data.employee]);
        }
    }, [data]);

    const onFormFinish = (form) => {
        const common = {
            employee: form.employee,
            src: "web",
        };

        const data = [
            {
                ...common,
                timestamp: getUtcDateTime(form.date, form.startTime),
            },
            {
                ...common,
                timestamp: getUtcDateTime(form.date, form.endTime),
            },
        ];
        axiosInstance.post("attendanceRawRecord", data).then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "fixAttendanceRawRecordForm", htmlType: "submit" }}
            title="Nuova timbratura"
        >
            <Form
                id="fixAttendanceRawRecordForm"
                form={form}
                layout="vertical"
                onFinish={onFormFinish}
            >
                <Form.Item name={"date"} label="Data">
                    <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>

                <Form.Item name={"employee"} label="Dipendente">
                    <Select
                        disabled
                        options={employees.map((x) => ({ value: x.id, label: x.fullName }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>

                <Space>
                    <Form.Item name={"startTime"} label="Inizio">
                        <DatePicker.TimePicker format="HH:mm" />
                    </Form.Item>

                    <Form.Item name={"endTime"} label="Fine">
                        <DatePicker.TimePicker format="HH:mm" />
                    </Form.Item>

                    <Button
                        type="link"
                        onClick={() => {
                            form.setFieldsValue({
                                startTime: shiftTimes[0],
                                endTime: shiftTimes[1],
                            });
                        }}
                    >
                        Carica orario lavorativo
                    </Button>
                </Space>
            </Form>
        </Modal>
    );
}
