import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import EmployeeTimesheet from "../attendance/EmployeeTimesheet";
import axiosInstance from "../common/api";
import BackTitle from "../common/BackTitle";
import { filterOption, requiredField } from "../common/common";

export default function Timesheets() {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const [data, setData] = useState();
    const [timesheetLoading, setTimesheetLoading] = useState(false);
    const period = Form.useWatch("period", form);

    useEffect(() => {
        if (period)
            axiosInstance
                .get("employeeCompact", {
                    params: {
                        startDate: period.startOf("month").format("YYYY-MM-DD"),
                        endDate: period.endOf("month").format("YYYY-MM-DD"),
                    },
                })
                .then(({ data }) =>
                    setEmployees(
                        data
                            .map((x) => ({ label: x.fullName, value: x.id }))
                            .sort((a, b) => a.label.localeCompare(b.label)),
                    ),
                );
    }, [period]);

    const nextIndex =
        (employees.findIndex((x) => x.value === data?.employeeId) + 1) % employees.length;
    const prevIndex =
        (employees.length + employees.findIndex((x) => x.value === data?.employeeId) - 1) %
        employees.length;

    return (
        <div>
            <Form
                colon={false}
                form={form}
                onFinish={setData}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
            >
                <Row>
                    <Col flex={"500px"}>
                        <Form.Item name={"period"} label={"Periodo"} rules={[requiredField]}>
                            <DatePicker.MonthPicker format="MMMM YYYY" style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item name={"employeeId"} label={"Dipendente"} rules={[requiredField]}>
                            <Select
                                options={employees}
                                filterOption={filterOption}
                                showSearch
                                disabled={!period}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        flex={"auto"}
                        style={{ display: "flex", placeItems: "end", placeContent: "end" }}
                    >
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Applica
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider />
            <Spin spinning={timesheetLoading}>
                {data && (
                    <>
                        <Row>
                            <Col span={8}>
                                {
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            form.setFieldValue(
                                                "employeeId",
                                                employees[prevIndex].value,
                                            );
                                            form.submit();
                                        }}
                                    >
                                        <LeftOutlined />
                                        {employees[prevIndex]?.label}
                                    </Button>
                                }
                            </Col>
                            <Col span={8} style={{ textAlign: "center" }}>
                                Registro presenze di
                                <br />
                                <span style={{ fontSize: 18 }}>
                                    <b>
                                        {employees.filter((x) => x.value === data?.employeeId)?.[0]
                                            ?.label ?? ""}
                                    </b>
                                </span>
                            </Col>
                            <Col span={8} style={{ textAlign: "end" }}>
                                {
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            form.setFieldValue(
                                                "employeeId",
                                                employees[nextIndex].value,
                                            );
                                            form.submit();
                                        }}
                                    >
                                        {employees[nextIndex]?.label}
                                        <RightOutlined />
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <EmployeeTimesheet
                            onLoadingChange={setTimesheetLoading}
                            employeeId={data?.employeeId}
                            period={data?.period}
                        />
                    </>
                )}
            </Spin>
        </div>
    );
}
