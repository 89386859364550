import {Button, Form, Input, message, Row, Select, Spin} from "antd";
import axiosInstance from "../../common/api";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import FormPage from "../../common/FormPage";
import LocaleDatePicker from "../../common/LocaleDatePicker";
import {requiredField} from "../../common/common";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";


export default function () {
    const navigate = useNavigate();
    const {attachmentId} = useParams();
    const [tags, setTags] = useState([])
    const [selectedTag, setSelectedTag] = useState();
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [form] = useForm();

    useEffect(() => {
        axiosInstance.get("tag")
            .then(({data}) => setTags(data))
        axiosInstance.get("/attachment/" + attachmentId)
            .then(({data}) => {
                setSelectedTag(data.attachmentTag)
                form.setFieldsValue({
                    tag: data.attachmentTag.id,
                    date: data.date && dayjs(data.date, "YYYY-MM-DD"),
                    dueDate: data.dueDate && dayjs(data.dueDate, "YYYY-MM-DD"),
                })
            })
            .finally(() => setDataLoading(false))
    }, []);

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFinish = (form) => {
        setLoading(true)
        form.date = form.date && form.date.format("YYYY-MM-DD")
        form.dueDate = form.dueDate && form.dueDate.format("YYYY-MM-DD")

        axiosInstance.patch(`/attachment/${attachmentId}`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Documento modificato con successo"))
            .finally(() => setLoading(false))
    }

    return <Spin spinning={dataLoading}>
        <FormPage form={form} onFinish={onFinish} title={"Modifica documento"}>
            <Form.Item hasFeedback name={"tag"} label={"Etichetta"} rules={[requiredField]}>
                <Select filterOption={filterOption} showSearch
                        onChange={value => setSelectedTag(tags.filter(x => x.id === value)[0])}
                        options={(tags ?? []).map(x => ({label: x.name, value: x.id}))}/>
            </Form.Item>
            <Form.Item hasFeedback name={"date"} label={"Data"}
                       rules={[selectedTag?.requireDate && requiredField].filter(x => !!x)}>
                <LocaleDatePicker/>
            </Form.Item>
            <Form.Item hasFeedback name={"dueDate"} label={"Data scadenza"}
                       rules={[selectedTag?.requireDueDate && requiredField,
                           ({getFieldValue}) => ({
                               validator(_, value) {
                                   const date = getFieldValue('date')
                                   if (!!value && !!date && value.diff(date, 'day') < 1) {
                                       return Promise.reject(new Error('La data di scadenza deve essere dopo la data del documento'));
                                   }
                                   return Promise.resolve();
                               },
                           }),
                       ].filter(x => !!x)}>
                <LocaleDatePicker/>
            </Form.Item>

            <Form.Item style={{textAlign: "end"}}>
                <Button type={"primary"} htmlType={"submit"} style={{width: 150}} loading={loading}>Salva</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}
