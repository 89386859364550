import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Row,
    Space,
    Spin,
    Tabs,
} from "antd";
import ContractDetails from "./ContractDetails";
import EmployeeAttachments from "./attachments/EmployeeAttachments";
import Title from "antd/lib/typography/Title";
import BackTitle from "../common/BackTitle";
import dayjs from "dayjs";
import ProfilePicture from "../common/ProfilePicture";
import { downloadFile } from "../common/common";
import { EditOutlined, IdcardOutlined } from "@ant-design/icons";
import PermissionRequired from "../common/PermissionRequired";
import Link from "antd/es/typography/Link";
import BadgeDetails from "./BadgeDetails";

export default function EmployeeProfile() {
    const { id } = useParams();
    const [employee, setEmployee] = useState({});
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        axiosInstance
            .get("employee/" + id)
            .then(({ data }) => setEmployee(data))
            .finally(() => setLoadingData(false));
    }, []);

    const items = [
        {
            key: "3",
            label: "Codice fiscale",
            children: employee.fiscalCode,
        },
        {
            key: "4",
            label: "Data di nascita",
            children: dayjs(employee.birthday, "YYYY-MM-DD").format("DD/MM/YYYY"),
        },
        {
            key: "5",
            label: "Telefono",
            children: employee.phoneNumber,
        },
        {
            key: "6",
            label: "E-mail",
            children: employee.email,
        },
        {
            key: "7",
            label: "Indirizzo",
            children: employee.address,
        },
        {
            key: "8",
            label: "Telefono aziendale",
            children: employee.businessPhoneNumber ?? "",
        },
        {
            key: "9",
            label: "Codice dipendente (Paghe)",
            children: employee.payrollId,
        },
        {
            key: "10",
            label: "Matricola (Paghe)",
            children: employee.externalId,
        },
    ];
    const tabItems = [
        {
            key: "1",
            label: "Contratto",
            children: <ContractDetails contract={employee.contract ?? {}} />,
        },
        {
            key: "2",
            label: "Documenti",
            children: <EmployeeAttachments employeeId={id} />,
        },
        {
            key: "3",
            label: "Badge",
            children: <BadgeDetails employee={employee}/>,
        },
    ];
    const onTabChange = (k) => {
        searchParams.set("tab", k);
        setSearchParams(searchParams, { replace: true });
    };
    return (
        <div>
            <Outlet />
            {/*<Row>
                <Col span={24} style={{textAlign: 'end'}}>
                    <Button icon={<IdcardOutlined/>} type="primary" onClick={() => downloadFile(`/employee/${employee.id}/badge`)}>
                        Stampa Badge
                    </Button>
                </Col>
            </Row>*/}
            <Spin spinning={loadingData}>
                <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1, lineHeight: 2 }}>
                            <Title level={2} style={{ margin: 0 }}>
                                {employee.fullName}
                            </Title>
                            <span style={{ fontSize: 20 }}>{employee.contract?.mansion.name}</span>
                        </div>
                        <ProfilePicture employeeId={id} hasImg={!!employee.photoRef} />
                    </div>
                    <div>
                        <PermissionRequired name="employee.update">
                            <Button type="link" icon={<EditOutlined/>} onClick={() => navigate("edit")}>
                                Modifica informazioni
                            </Button>
                        </PermissionRequired>
                        <Descriptions
                            items={items}
                            style={{marginTop: 10}}
                            column={2}
                            /*extra={
                            <Button type={"link"} onClick={() => navigate("edit")}>
                                Modifica anagrafica
                            </Button>
                        }*/
                        />
                    </div>
                    {employee.contract ? (
                        <Tabs
                            type="card"
                            defaultActiveKey={searchParams.get("tab") ?? "1"}
                            onChange={onTabChange}
                            items={tabItems}
                        />
                    ) : (
                        <Space direction={"vertical"} style={{ width: "100%" }}>
                            <Divider />
                            <div
                                style={{
                                    width: "100%",
                                    height: 200,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#0f0f0f0d",
                                }}
                            >
                                <Card style={{ minWidth: 200, width: "30%" }}>
                                    <Space
                                        direction={"vertical"}
                                        align={"center"}
                                        size={20}
                                        style={{ minWidth: "100%" }}
                                    >
                                        <span>Nessun contratto è stato inserito.</span>
                                        <Button
                                            onClick={() => navigate("contract/new")}
                                            type={"primary"}
                                        >
                                            Nuovo contratto
                                        </Button>
                                    </Space>
                                </Card>
                            </div>
                        </Space>
                    )}
                </div>
            </Spin>
        </div>
    );
}
