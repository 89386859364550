import { Button, Space, Table, Tag } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../common/api";
import { Link, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { date2locale, downloadFile } from "../../common/common";
import PdfViewer from "../../common/PdfViewer";
import Toolbar from "../../common/Toolbar";
import { UserInfoContext } from "../../FetchUserInfo";

const toDayjs = (date) => dayjs(date ?? "1970-01-01", "YYYY-MM-DD");

export default function ({ employeeId }) {
    const [tags, setTags] = useState([]);
    const user = useContext(UserInfoContext);
    const navigate = useNavigate();
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);

    const columns = (employeeId, tags) => [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            width: 70,
            render: (id) => (
                <Link
                    onClick={() => setSelectedAttachment(`employee/${employeeId}/attachment/${id}`)}
                >
                    {id}
                </Link>
            ),
        },
        {
            key: "tag",
            title: "Etichetta",
            filters: tags,
            dataIndex: "tag",
            onFilter: (value, record) => value === record.tag.id,
            filterSearch: true,
            width: 250,
            render: (tag) => <Tag color={tag.color}>{tag.name}</Tag>,
        },
        {
            key: "date",
            dataIndex: "date",
            render: (v) => v && date2locale(v),
            title: "Data",
            width: 150,
            sorter: (a, b) => toDayjs(a.date).diff(toDayjs(b.date)),
        },
        {
            key: "dueDate",
            dataIndex: "dueDate",
            render: (v) => v && date2locale(v),
            title: "Data di scadenza",
            ellipsis: true,
            width: 150,
            sorter: (a, b) => toDayjs(a.dueDate).diff(toDayjs(b.dueDate)),
        },
    ];

    const fetch = () => {
        axiosInstance
            .get("attachment", {
                params: {
                    employee: employeeId,
                    pageSize: 9999,
                },
            })
            .then(({ data }) => setData(data.data));
    };

    useEffect(() => {
        axiosInstance.get("/tag").then(({ data }) => setTags(data));
        fetch()
    }, []);

    const deleteRecord = (id) => {
        axiosInstance.delete(`attachment/${id}`).then(fetch);
    };

    return (
        <>
            <PdfViewer url={selectedAttachment} onClose={() => setSelectedAttachment(null)} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <div>
                    <Toolbar
                        selectedKeys={selectedRowKeys}
                        addAction={
                            user?.permissions.includes("employeeAttachment.create")
                                ? () => navigate("attachment/new")
                                : null
                        }
                        editAction={
                            user?.permissions.includes("employeeAttachment.update")
                                ? (key) => navigate(`attachment/${key}/edit`)
                                : null
                        }
                        deleteAction={
                            user?.permissions.includes("employeeAttachment.delete")
                                ? (keys) => deleteRecord(keys[0])
                                : null
                        }
                        extra={(keys) => (
                            <>
                                <Button
                                    type="text"
                                    disabled={keys.length !== 1}
                                    icon={<DownloadOutlined />}
                                    onClick={() =>
                                        downloadFile(`employee/${employeeId}/attachment/${keys[0]}`)
                                    }
                                >
                                    Scarica
                                </Button>
                            </>
                        )}
                    />
                    <Table
                        tableLayout={"fixed"}
                        size="small"
                        columns={columns(
                            employeeId,
                            tags.map((x) => ({ text: x.name, value: x.id })),
                        )}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: setSelectedRowKeys,
                            type: "radio",
                        }}
                        dataSource={data}
                        rowKey="id"
                        onRow={(record) => ({
                            onClick: () => setSelectedRowKeys([record.id]),
                        })}
                        scroll={{ x: 1190 }}
                        pagination={{ showSizeChanger: true }}
                    />
                </div>
            </div>
        </>
    );
}
