import { Checkbox, DatePicker, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import { filterOption } from "../common/common";
import dayjs from "dayjs";

export default function ({ open, onFinish, onCancel, data }) {
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const [justifications, setJustifications] = useState([]);

    const allDay = Form.useWatch("allDay", form);
    const date = Form.useWatch("date", form);

    useEffect(() => {
        document.body.style.overflow = 'inherit'
        if (open) {
            axiosInstance
                .get("justificationType", { params: { sign: -1 } })
                .then(({ data }) => setJustifications(data));
        }else{
            form.resetFields()
        }
    }, [open]);

    useEffect(() => {
        if (open && !data && !!date) {
            const dateStr = date.format("YYYY-MM-DD");
            axiosInstance
                .get("employeeCompact", { params: { startDate: dateStr, endDate: dateStr } })
                .then(({ data }) => setEmployees(data));
        }
    }, [open, data, date]);

    useEffect(() => {
        if (data) {
            form.setFieldValue("employee", data.employee.id);
            form.setFieldValue("date", dayjs(data.date));
            setEmployees([data.employee]);
        } else form.resetFields();
    }, [data]);

    const onFormFinish = (form) => {
        const data = {
            ...form,
            startDate: form.date.format("YYYY-MM-DD"),
            endDate: form.date.format("YYYY-MM-DD"),
            excludeNonWorkingDays: false,
            minutes: form.minutes
                ? dayjs.duration(form.minutes.diff(form.minutes.startOf("day"))).asMinutes()
                : null,
        };
        axiosInstance.post("justification", data).then(() => onFinish());
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "newAttendanceJustificationForm", htmlType: "submit" }}
            title="Nuovo giustificativo"
        >
            <Form
                id="newAttendanceJustificationForm"
                form={form}
                layout="vertical"
                onFinish={onFormFinish}
            >
                <Form.Item name={"date"} label="Data">
                    <DatePicker disabled={!!data} format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name={"employee"} label="Dipendente">
                    <Select
                        disabled={!date || !!data}
                        options={employees.map((x) => ({ value: x.id, label: x.fullName }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                <Form.Item name={"justificationTypeId"} label="Giustificativo">
                    <Select
                        options={justifications.map((x) => ({
                            label: x.id + " - " + x.name,
                            value: x.id,
                        }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                <Form.Item
                    name={"allDay"}
                    label="Tutta la giornata"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox />
                </Form.Item>
                {!allDay && (
                    <Form.Item name={"minutes"} label="Ore">
                        <DatePicker.TimePicker format="HH:mm" showNow={false} />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
}
