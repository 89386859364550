import { Button, Descriptions, Space, Tag } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { date2locale } from "../common/common";
import { UserInfoContext } from "../FetchUserInfo";

export default function ContractDetails({ contract }) {
    const navigate = useNavigate();
    const user = useContext(UserInfoContext)
    const items = [
        {
            key: "1",
            label: "Data assunzione",
            children: date2locale(contract.startDate),
        },
        {
            key: "2",
            label: "Data cesssazione",
            children: contract.terminationDate ? date2locale(contract.terminationDate) : "-",
        },
        {
            key: "3",
            label: "Motivo cessazione",
            children: contract.terminationReason?.title ?? "-",
        },
        {
            key: "5",
            label: "Tipo contratto",
            children: contract.contractType?.type,
        },
        {
            key: "6",
            label: "Scadenza contratto",
            children: contract.dueDate ? date2locale(contract.dueDate) : "-",
        },
        {
            key: "7",
            label: "Mansione",
            children: (
                <div style={{ minWidth: "100%" }}>
                    <Tag key={contract.mansion.id}>{contract.mansion.name}</Tag>
                </div>
            ),
        },
        {
            key: "8",
            label: "Orario di lavoro",
            children: contract.shift?.name,
        },
    ].filter((x) => !!x);
    return (
        <Descriptions
            items={items}
            column={1}
            extra={
                user?.permissions.includes("contract.update") && <Button type={"link"} onClick={() => navigate("contract/edit")}>
                    Modifica contratto
                </Button>
            }
        />
    );
}
